var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-progress-circular',{attrs:{"size":"20","indeterminate":"","color":"rgba(0, 0, 0, 0.87)"}}):_c('div',[_c('v-simple-table',{staticClass:"table-samp-result",attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("ORG")]),_c('th',[_vm._v("V_ANO")]),_c('th',{staticClass:"text-right"},[_vm._v("P_ANO (%)")]),_c('th',{staticClass:"text-right"},[_vm._v("UC_ANO_GWH")]),_c('th',{staticClass:"text-right"},[_vm._v("BE_ANO_GWH")])])]),_c('tbody',_vm._l((_vm.resultado),function(item,j){return _c('tr',{key:j,class:j % 2 === 1 ? 'zebra1' : ''},[_c('td',[_vm._v(_vm._s(item.org))]),_c('td',{class:{
                'status-samp-ok': item.v_ano.trim().toUpperCase() === 'OK',
                'status-samp-alerta': item.v_ano.trim().toUpperCase() !== 'OK'
              }},[_vm._v(" "+_vm._s(_vm._f("toUpperCase")(item.v_ano))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBR")(item.p_ano))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBR")(item.uc_ano_gwh))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBR")(item.be_ano_gwh))+" ")])])}),0),_c('tfoot',[_c('tr',[_c('th'),_c('th',{staticClass:"text-right"},[_vm._v("TOTAL")]),_c('td',{staticClass:"text-right"},[_vm._v("--")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBR")(_vm.totalUcAnoGwh))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBR")(_vm.totalBeAnoGwh))+" ")])]),_c('tr',[_c('th'),_c('th',{staticClass:"text-right"},[_vm._v("DIF GWH UC BE")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBR")(_vm.difUcXBeAnoGwh))+" ")]),_c('td',{staticClass:"text-right",attrs:{"colspan":"2"}},[_c('v-btn',{attrs:{"small":"","title":"Baixar o resultado","outlined":"","loading":_vm.loadingBaixarResultadoTeste},on:{"click":_vm.baixarResultadoTeste}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-download ")])],1)],1)]),_c('tr',[_c('th'),_c('th',{staticClass:"text-right"},[_vm._v("BE ANO GWH")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBR")(_vm.totalBeAnoGwh))+" ")]),_c('td',{attrs:{"colspan":"2"}})]),_c('tr',[_c('th'),_c('th',{staticClass:"text-right"},[_vm._v("% Descont FOD")]),_c('td',{staticClass:"text-right",class:{
                'status-samp-ok':
                  _vm.percDescontFod <=
                  _vm.parametrosExtrasTeste.percDescontFodMediasOuGrandesEmpresas,
                'status-samp-erro':
                  _vm.percDescontFod >
                  _vm.parametrosExtrasTeste.percDescontFodMediasOuGrandesEmpresas
              }},[_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBR")(_vm.percDescontFod))+" ")]),_c('td',{attrs:{"colspan":"2"}},[_vm._v(" é maior que "+_vm._s(_vm._f("parseNumberToFloatBR")(_vm.parametrosExtrasTeste.percDescontFodMediasOuGrandesEmpresas))+"% para médias e grandes ou é maior que "+_vm._s(_vm._f("parseNumberToFloatBR")(_vm.parametrosExtrasTeste.percDescontFodPequenasEmpresas))+"% para pequenas? ")])]),_c('tr',[_c('th'),_c('th',{staticClass:"text-right"},[_vm._v("GigaWatt FOD")]),_c('td',{staticClass:"text-right"}),_c('td',{attrs:{"colspan":"2"}})])])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }