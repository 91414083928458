<template>
  <div>
    <v-progress-circular
      v-if="loading"
      size="20"
      indeterminate
      color="rgba(0, 0, 0, 0.87)"
    />
    <div v-else>
      <v-simple-table
        dense
        class="table-samp-result"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th>ORG</th>
              <th>V_ANO</th>
              <th class="text-right">P_ANO (%)</th>
              <th class="text-right">UC_ANO_GWH</th>
              <th class="text-right">BE_ANO_GWH</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, j) in resultado"
              :key="j"
              :class="j % 2 === 1 ? 'zebra1' : ''"
            >
              <td>{{ item.org }}</td>
              <td
                :class="{
                  'status-samp-ok': item.v_ano.trim().toUpperCase() === 'OK',
                  'status-samp-alerta': item.v_ano.trim().toUpperCase() !== 'OK'
                }"
              >
                {{ item.v_ano | toUpperCase }}
              </td>
              <td class="text-right">
                {{ item.p_ano | parseNumberToFloatBR }}
              </td>
              <td class="text-right">
                {{ item.uc_ano_gwh | parseNumberToFloatBR }}
              </td>
              <td class="text-right">
                {{ item.be_ano_gwh | parseNumberToFloatBR }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th></th>
              <th class="text-right">TOTAL</th>
              <td class="text-right">--</td>
              <td class="text-right">
                {{ totalUcAnoGwh | parseNumberToFloatBR }}
              </td>
              <td class="text-right">
                {{ totalBeAnoGwh | parseNumberToFloatBR }}
              </td>
            </tr>
            <tr>
              <th></th>
              <th class="text-right">DIF GWH UC BE</th>
              <td class="text-right">
                {{ difUcXBeAnoGwh | parseNumberToFloatBR }}
              </td>
              <td
                class="text-right"
                colspan="2"
              >
                <v-btn
                  small
                  title="Baixar o resultado"
                  outlined
                  @click="baixarResultadoTeste"
                  :loading="loadingBaixarResultadoTeste"
                >
                  <v-icon
                    small
                    color="primary"
                  >
                    mdi-download
                  </v-icon>
                </v-btn>
              </td>
            </tr>
            <tr>
              <th></th>
              <th class="text-right">BE ANO GWH</th>
              <td class="text-right">
                {{ totalBeAnoGwh | parseNumberToFloatBR }}
              </td>
              <td colspan="2"></td>
            </tr>
            <tr>
              <th></th>
              <th class="text-right">% Descont FOD</th>
              <td
                class="text-right"
                :class="{
                  'status-samp-ok':
                    percDescontFod <=
                    parametrosExtrasTeste.percDescontFodMediasOuGrandesEmpresas,
                  'status-samp-erro':
                    percDescontFod >
                    parametrosExtrasTeste.percDescontFodMediasOuGrandesEmpresas
                }"
              >
                {{ percDescontFod | parseNumberToFloatBR }}
              </td>
              <td colspan="2">
                é maior que
                {{
                  parametrosExtrasTeste.percDescontFodMediasOuGrandesEmpresas
                    | parseNumberToFloatBR
                }}% para médias e grandes ou é maior que
                {{
                  parametrosExtrasTeste.percDescontFodPequenasEmpresas
                    | parseNumberToFloatBR
                }}% para pequenas?
              </td>
            </tr>
            <tr>
              <th></th>
              <th class="text-right">GigaWatt FOD</th>
              <td class="text-right">
                <!-- {{ parametrosExtrasTeste.gigaWattFod | parseNumberToFloatBR }} --> <!-- Comentado pois parece que a ANEEL não preenche esse campo "gigaWattFod"   -->
              </td>
              <td colspan="2"></td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import TabelaResultadoTesteSampMixins from './TabelaResultadoTesteSampMixins';
export default {
  name: 'TesteEneXBeSigrAnoGwh',
  mixins: [TabelaResultadoTesteSampMixins],
  data: () => ({
    parametrosExtrasTeste: {
      gigaWattFod: 0,
      percDescontFodMediasOuGrandesEmpresas: 0,
      percDescontFodPequenasEmpresas: 0
    }
  }),
  computed: {
    totalUcAnoGwh() {
      return this.resultado.reduce(
        (acumulador, item) => (acumulador += Number(item.uc_ano_gwh)),
        0
      );
    },
    totalBeAnoGwh() {
      return this.resultado.reduce(
        (acumulador, item) => (acumulador += Number(item.be_ano_gwh)),
        0
      );
    },
    difUcXBeAnoGwh() {
      return this.totalUcAnoGwh - this.totalBeAnoGwh;
    },
    percDescontFod() {
      if (this.totalBeAnoGwh === 0) return 100;
      return (
        100 *
        Math.abs(
          (
            Math.abs(this.difUcXBeAnoGwh) // - Math.abs(this.parametrosExtrasTeste.gigaWattFod) // Comentado pois parece que a ANEEL não preenche esse campo "gigaWattFod"
          ) / this.totalBeAnoGwh
        )
      );
    }
  }
};
</script>
<style scoped>
.status-samp-ok,
.status-samp-erro {
  font-size: 12px !important;
}
</style>
